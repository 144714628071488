<template>
  <div class="ladies project-component">
    <div class="fullsection">
      <p>
        After working as a freelance developer and designer for SEPEFREI for a year, I got to manage an amazing team of
        students as the president of Efrei’s Junior Enterprise. Working like an ESN, we provide websites and
        applications, but also services such as consulting and design to companies and independant clients.
        I handled client accounts, issues happening during the product development or during the warranty period, and
        communicated with developers to solve problems and make sure they worked in the best conditions possible,
        learning the skills they were trying to upgrade.
      </p>
      <br />
      <p>
        Generating 100k in sales during my term, we ended up being part of the <strong>30 best junior Enterprises</strong> in France.

      </p>
      <br />
    </div>
    <div class="full-section half-section-media">
      <img src="@/assets/projects/sepefrei/album/1.png" alt="" />
    </div>
    <div class="half-section">
      <p>
        I implemented a collaboration strategy with our school, with monthly meetings with the company relations staff
        to orient our growth strategy in the same direction as that of our school. But also organized conferences,
        workshops and processes to give access to as many students of my school as possible the knowledge and
        connections that the Junior Enterprise can provide.
        This allowed us to be finalists in Engie’s price for the “Best partner of superior education”.
      </p>
      <br>
      <p>
        I was also heavily involved in using the Junior Enterprise to act on my values, such as inclusivity and
        accessibility. Thus my team and I animated workshops on making the Junior enterprise and in term our schools
        more inclusive for students with disabilities.
      </p>
      <br>
      <p>
        I also worked hand in hand with Jamais Sans Elles to promote equality for women in the workplace and in schools,
        as this is an issue close to my heart especially in the computer science field
      </p>
    </div>
    <div class="half-section half-section-media">
      <img src="@/assets/projects/sepefrei/album/3.png" alt="" />
    </div>

    <div class="lesson-box">
      <h2>What did I learn ?</h2>
      <br />
      <p>
        This experience made me grow a lot, as a developer first as I took on my first professional missions there and
        got to know real world client requirements for technical products. But also as a person as handling hardships as
        a manager, especially during the Covid 19 crisis and learning to work from home as a team - made me confront
        myself with my own flaws and weaknesses.
      </p>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "Sepefrei",
};
</script>
<style scoped>

</style>